<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) What is the concentration of hydroxide ions in neutral water at 37°C (body temperature)
        if
        <stemble-latex content="$\text{K}_w$" />
        at this temperature is
        <stemble-latex content="$2.5\times 10^{-14}?$" />
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question513',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$4.0\\times 10^{-8}$', value: '4.0e-8'},
        {text: '$6.3\\times 10^{-8}$', value: '6.3e-8'},
        {text: '$1.0\\times 10^{-7}$', value: '1.0e-7'},
        {text: '$1.6\\times 10^{-7}$', value: '1.6e-7'},
        {text: '$2.5\\times 10^{-7}$', value: '2.5e-7'},
      ],
    };
  },
};
</script>
